import request from './request'

const api = {
	// 获取列表
	lists: '/classstudycate/lists',
	// 添加
	add: '/classstudycate/add',
	// 删除
	dele: '/classstudycate/del',
	// 编辑
	edit: '/classstudycate/update',
	// 详情
	info: '/classstudycate/read',
}

export {
	request,
	api
}
